<template>
  <div class="attention f-w3">
    <div class="label d-flex justify-content-center f-w6">注意事項</div>
    <div class="attention__wrap">
      <pre>
・Superオファー中の案件にエントリーする場合、同じ日付の案件に複数エントリーすることが可能です。同日で複数エントリーされた場合、1つでもマッチングが成立した場合、同日のその他エントリーはすべて自動でキャンセルされます。
 
・マッチングが成立するまでは、女性は自由にエントリーすること、キャンセルすることが可能です。
  
・男性のマッチングの仕組みとして、２名Superオファーがあった場合、2名以上エントリーしている人がいないと、マッチング成立させることが出来ません。
   
・男性がSuperオファーを出したものにエントリーをしたが、Superオファーが集まらず、自動キャンセルになることや、男性自身の都合により、マッチングをせずに案件を取り下げ、キャンセルする場合もございます。
マッチングが成立したのにも関わらず、男性が開催日7日以内にキャンセルした場合は支払ポイントの50%の有償、2日前のキャンセルをした場合は支払ポイントの100%が有償になっておりますので、その場合女性は獲得ポイントをその中から付与されます。
男性がキャンセルした
50%有償の場合→50%のポイントを全て女性が獲得できる。
100%有償の場合→本来そのマッチングが成立することによって得られるポイントを獲得できる。
オファーにエントリーした場合
マッチングは基本、女性は2名以上なので、他の女性のSuperオファーをしないとオファーは成立しません。
Superオファーが集まり、男性がマッチングを成立させればオファーも成立。Superオファーが集まらなかったり、男性がマッチングを成立させなかった場合は、オファー不成立になります。

女性がマッチングが成立しているにも関わらずキャンセルをしてしまった場合

獲得ポイントの減額、ランクの降格、悪質だった場合は使用制限させて頂くこともありますのでご注意ください。
      </pre>
    </div>
  </div>
</template>

<script>
export default {
  name: "Attention"
};
</script>

<style scoped lang="scss">
.attention {
  width: 337px;
  margin: 0 -1px;
  text-align: left;
  padding: 0 0 30px 0;
  font-size: 14px;
  &__wrap {
    height: 290px;
    overflow-y: scroll;
  }
  .label {
    margin-top: 32px;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
  }
  pre {
    margin-left: 0;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    color: #000000;
    font-family: "Hiragino Kaku Gothic Pro W3";
    font-size: 14px;
    line-height: 21px;
  }
}
@media only screen and (min-width: 1079px) {
  .attention {
    width: 562px;
    margin: 0 -1px;
    text-align: left;
    padding: 0 20px 60px 20px;
    font-size: 14px;
    &__wrap {
      height: 400px;
      overflow-y: scroll;
    }
    .label {
      margin-top: 34px;
      margin-bottom: 25px;
      font-size: 20px;
      line-height: 26px;
      color: #000000;
    }
    pre {
      margin-left: 0;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
      color: #000000;
      font-family: "Hiragino Kaku Gothic Pro W3";
      font-size: 16px;
      line-height: 21px;
    }
  }
}
</style>
